@media only screen and (max-width: 411px) {
  .Home-logo {
    max-width: 98%;
    margin-left: -49%;
  }

  .Footer-center {
    flex: 1;
    order: 2;
  }
}

@media only screen and (max-width: 499px) {
  .Home-intro {
    width: 100%;
    left: 0;
    margin-left: 0;
    top: 455px;
  }
}

@media only screen and (max-width: 545px) {
  .Map-footer2 div {
    flex: none;
  }
}

@media only screen and (max-width: 569px) {
  .Circle-answers {
    width: 295px;
    margin: 0 auto;
  }

  .Circles-circle {
    width: 160px;
    direction: ltr;
    overflow: hidden;
  }

  .Circles-value {
    width: 50px;
    background: #00003a;
  }

  .Circles-text {
    width: 85px;
    background: #00003a;
  }
}

@media only screen and (max-width: 1023px) {
  body {
    min-width: 0;
    max-width: 100%;
    overflow-x: hidden;
  }

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .Home {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0;
  }

  .TopBar {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0;
  }

  .TopBar-logo {
    right: 0;
    left: auto;
  }

  body.ar .TopBar-logo {
    right: auto;
    left: 0;
  }

  .TopBar-language, .TopBar-languagemenu {
    right: auto;
    left: 40px;
  }

  body.ar .TopBar-language, body.ar .TopBar-languagemenu {
    right: 40px;
    left: auto;
  }

  .TopBar-menu img {
    width: 20px;
    height: 20px;
  }

  .Map-sidebar-left {
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 4;
    background: #ff6c00;
    padding: 10px;
    margin-top: 0;
  }

  .Map-sidebar {
    width: 300px;
  }

  body.ar .Map-sidebar-left {
    left: auto;
    right: 0;
  }

  .Map-sidebar-collapsed {
    display: none;
  }

  .Map-sidebar-right {
    display: none;
  }

  .Map h2 {
    color: #00003a;
    font-size: 16px;
    font-weight: 700;
  }

  /* make sure the title exported on mobile with correct color */
  .Map-export-title h2 {
    color: #fc6d04;
  }

  .Map-select, .Map-menu-extra > div {
    font-size: 16px;
    color: #00003a;
    border-color: #00003a;
    background-color: #ff6c00;
    background-image: url(miniarrowupblue.svg);
  }

  .Map-select.Map-expanded {
    border-bottom: 1px solid #00003a;
  }

  .Map-first-option {
    border-color: #00003a;
  }

  .Map-collapsed {
    background-image: url(miniarrowblue.svg);
  }

  .Map {
    width: 100%;
    padding: 5px;
    padding-top: 110px;
    box-sizing: border-box;
  }

  .Map-expanded .Map-governorate-options {
    height: 610px;
  }
  
  .Map-expanded .Map-category-options {
    height: 290px;
  }

  .Map-content {
    max-width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .Map-national-overview,
  .Map-national-overview:hover {
    width: 192px;
    border-color: #00003a;
    color: #00003a;
  }

  .Map-range div, .Map-range p {
    width: 200px;
  }

  .Map-footer2 {
    flex-wrap: wrap;
  }

  .Map-footer span {
    padding: 0 10px;
  }

  .Footer {
    flex-wrap: wrap;
    box-sizing: border-box;
    max-width: 100%;
    padding: 10px;
  }

  .Footer-container {
    width: 100%;
  }

  .Footer-left, .Footer-center, .Footer-right {
    min-width: 120px;
  }
}
