.Circles-answers {
  margin: 0 auto;
  position: relative;
  width: 570px;
  max-width: 100%;
}

.Circles-answer {
  font-family: Roboto;
  color: #fff;
  position: absolute;
}

.Circles-answer > div {
  display: inline-block;
  vertical-align: middle;
}

.Circles-circle {
  width: 225px;
}

.Circles-value {
  font-size: 24px;
  font-weight: 300;
  width: 120px;
  text-align: center;
}

.Circles-text {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  width: 225px;
}

body.ar .Circles-text {
  text-align: right;
}

body.ar .Circles-circle {
  transform: rotate(-180deg);
}
