.Footer-container {
  width: 1310px;
  margin: 0 auto;
  border-top: 1px solid #0b32a0;
}

.Footer {
  width: 850px;
  margin: 0 auto;
  display: flex;
  padding-top: 32px;
}

.Footer-left {
  flex: 1;
}

.Footer-center {
  flex: 1;
}

.Footer-center img {
  height: 32px;
}

.Footer-right 

.Footer .Footer-download {
  height: 32px;
  margin: 0;
  display: flex;
  align-items: center;
}

.Footer-right {
  flex: 2;
}

.Footer-right img {
  width: 80px;
}

.Footer-eulogo {
  position: relative;
  top: -10px;
}

.Footer-right img:last-child {
  margin-left: 20px;
}

.ar .Footer-right img:last-child {
  margin-left: auto;
  margin-right: 20px;
}

.Footer span, .Footer a, .Footer a:visited, .Footer-container address, .Footer-right p {
  font-weight: 700;
  font-family: Roboto, sans-serif;
  color: #fff;
  text-decoration: underline;
  font-size: 13px;
  cursor: pointer;
  display: block;
  margin: 10px 0;
}

.Footer-container address, .Footer-right p {
  font-weight: 300;
  text-decoration: none;
  cursor: default;
  margin-top: 32px;
  font-style: normal;
}

.Footer-container address {
  display: block;
  padding: 10px;
  padding-bottom: 40px;
  width: 850px;
  max-width: 100%;
  margin: 0 auto;
}

.Footer-right p {
  margin-top: 0;
}
