.ar {
  direction: rtl;
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}

/* hide footer on homepage */
.App-home .Footer {
	display: none;
}