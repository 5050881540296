.About {
  padding: 51px 15px 51px 15px;
  min-height: 600px;
  width: 850px;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box;
}

.About p {
  font-family: Roboto, sans-serif;
  color: #fff;
  line-height: 1.5em;
}

.About * {
  font-family: Roboto, sans-serif !important;
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 300;
}

.About .c5 {
  display: block;
  margin-bottom: -10px;
  font-size: 24px !important;
}

.About h2 {
  margin-top: -20px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 28px !important;
}

body.ar .About * {
  direction: rtl !important;
  text-align: right !important;
}

@media only screen and (min-width: 500px) {
  .About * {
    font-size: 16px !important;
  }

  .About h2 {
    font-size: 65px !important;
  }
}