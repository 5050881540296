.Home {
  width: 100%;
  height: 100%;
  min-height: 900px;
  background-size: cover;
  position: absolute;
  left: 0;
  margin-left: 0;
  top: 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  z-index: 0;
}

.Home-mask {
  background: #00003A;
  opacity: 0.6;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  min-height: 900px;
  height: 100%;
  z-index: 1;
}

.Home-logo {
  width: 412px;
  height: 412px;
  position: absolute;
  top: 92px;
  left: 50%;
  margin-left: -206px;
  z-index: 2;
}

.Home-intro {
  position: absolute;
  z-index: 3;
  top: 502px;
  left: 50%;
  margin-left: -250px;
  font-family: Roboto, sans-serif;
  font-weight: 900;
  font-size: 24px;
  width: 500px;
  color: #fff;
  line-height: 1.5em;
  text-align: center;
}

.Home-enter {
  margin-top: 22px;
  display: inline-block;
  cursor: pointer;
  background-color: #FF6C00;
  font-size: 20px;
  width: 300px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.Home-credit {
  position: absolute;
  z-index: 2;
  right: 50%;
  bottom: 20px;
  transform: translate(50%, 0);
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}


.Home-credit a {
  color: white;
}
