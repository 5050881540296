.TopBar {
  width: 1310px;
  position: absolute;
  left: 50%;
  margin-left: -655px;
  top: 0;
  z-index: 4;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #FF6C00;
}

.TopBar-content {
  display: flex;
  height: 20px;
  background: #00003A;
  padding: 15px;
  align-items: center;
}

.TopBar-link {
  cursor: pointer;
}

.TopBar-link img {
  width: 16px;
  height: 16px;
  vertical-align: top;
}

.TopBar-link span {
  vertical-align: bottom;
}

.TopBar-language,
.TopBar-languagemenu {
  position: absolute;
  left: 200px;
  right: auto;
  width: 100px;
  text-align: left;
}

.TopBar-languagemenu {
  top: 2px;
  background: #00003A;
}

.TopBar-language {
  width: 70px;
}

.TopBar-language,
.TopBar-languagemenu > span {
  display: block;
  padding: 15px;
}

.TopBar-languagemenu > span + span {
  margin-top: -24px;
}

.TopBar-logo {
  position: absolute;
  z-index: 5;
  left: -10px;
  right: auto;
  top: 0;
  width: 150px;
  cursor: pointer;
}

.TopBar-download {
  margin-left: auto;
  margin-right: 0;
}

.TopBar hr {
  border: 0;
  margin: 0;
  border-bottom: 1px solid #FF6C00;
  height: 0;
  /* width: 95%; */
  margin: 0 auto;
}

body.ar .TopBar-language,
body.ar .TopBar-languagemenu {
  right: 200px;
  left: auto;
}

body.ar .TopBar-logo {
  right: -10px;
  left: auto;
}

body.ar .TopBar-download {
  margin-right: auto;
  margin-left: 0;
}
