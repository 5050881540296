.Map {
  padding: 51px 15px 0 15px;
  width: 1310px;
  margin: 0 auto;
  display: flex;
}

.Map h2 {
  margin: 18px 0;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.55);
  font-size: 15px;
}

.Map-total {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.Map-select, .Map-menu-extra > div {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #FF6C00;
  font-weight: bold;
  border: 0;
  padding: 10px 0;
  background-color: #00003A;
  background-repeat: no-repeat;
  background-position: right 18px;
  background-image: url(miniarrowup.svg);
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 192px;
  margin-bottom: 24px;
}

.Map-menu-extra > div {
  background-image: none !important;
}

body.ar .Map-select {
  background-position: left 18px;
}

.Map-select.Map-expanded {
  border-bottom: 1px solid #FF6C00;
}

.Map-collapsed {
  background-image: url(miniarrow.svg);
}

.Map-options {
  max-height: 0;
  overflow: hidden;
  transition: max-height 600ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
}

.Map-expanded .Map-governorate-options {
  max-height: 650px;
  height: auto;
}

.Map-expanded .Map-category-options {
  max-height: 350px;
  height: auto;
}

.Map-menu-extra img {
  width: 32px;
  height: 32px;
}

.Map-option {
  font-weight: bold;
  padding: 5px;
  display: block;
  cursor: pointer;
}

.Map-first-option {
  border-bottom: 1px solid #FF6C00;
}

.Map-sidebar {
  width: 200px;
  margin: 0;
  margin-top: 62px;
  display: inline-block;
}

.Map-content {
  width: 850px;
  display: inline-block;
  margin: 0 30px;
  min-height: 720px;
  text-align: center;
  padding-top: 18px;
}

.Map-content h2 {
  color: #fff;
}

.Map-pager {
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 24px;
  font-weight: 300;
}

.Map-pager img {
  height: 16px;
  cursor: pointer;
}

.Map-pager span {
  display: inline-block;
  margin: 0 45px;
}

.Map-content h3 {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  color: #fff;
  margin: 0;
  padding: 18px 0 3px 0;
  border-bottom: 1px solid #0b32a0;
  min-height: 48px;
  overflow: hidden;
  display: block;
}

.Map-range {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  color: #fff;
  margin: 30px 0;
}

.Map-range div {
  display: inline-block;
  border-radius: 12px;
  width: 365px;
  height: 20px;
  background: #363562;
  background: -moz-linear-gradient(left, #363562 0%, #fc6d04 100%);
  background: -webkit-linear-gradient(left, #363562 0%,#fc6d04 100%);
  background: linear-gradient(to right, #363562 0%,#fc6d04 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#363562', endColorstr='#fc6d04',GradientType=1 );
}

.Map-range p {
  font-size: 12px;
  display: flex;
  width: 365px;
  margin: 0 auto;
}

.Map-range span {
  flex: 1;
  text-align: left;
}

.Map-range span + span {
  text-align: right;
}

.Map-footer {
  padding: 16px 0;
  margin: 16px 0;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #fff;
  border-top: 1px solid #0b32a0;
  border-bottom: 1px solid #0b32a0;
}

.Map-footer div {
  flex: 1;
  text-align: center;
}

.Map-footer span {
  font-weight: 300;
  padding: 0 32px;
}

.Map-footer b {
  font-weight: 700;
}

.Map-footer2 {
  padding: 16px 0;
  margin: 16px 0;
  padding-top: 0;
  margin-top: 0;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #fff;
  line-height: 36px;
}

.Map-footer2 div {
  flex: 1;
  text-align: center;
}

.Map-footer2 span {
  font-weight: 300;
  padding: 0 32px;
  vertical-align: top;
}

.Map-share img {
  height: 32px;
  padding: 0 5px;
  cursor: pointer;
}

.Map-footer2 .Map-share-link {
  padding: 0;
}

.Map-play img {
  cursor: pointer;
}

.Map-export-title h2 {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  color: #fc6d04;
  font-weight: bold;
}

.Map-export-source {
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 14px;
  text-align: left;
}

.Map-national-overview {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #fc6d04;
  width: 192px;
  text-align: center;
  font-weight: normal;
  border: 1px solid #fc6d04;
  cursor: pointer;
  padding: 8px 1px;
}

.Map-national-overview:focus {
  border-width: 2px;
  padding: 7px 0;
}

.Map-national-overview-active,
.Map-national-overview:hover {
  color: #fff;
  background-color: #fc6d04;
}

.Map-download-site {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #fc6d04;
  width: 192px;
  font-weight: normal;
  border-bottom: 1px solid #0b32a0;
  padding: 8px 0;
  cursor: pointer;
  margin-top: 75px;
}

.Map-download-mobile {
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  width: 195px !important;
  justify-content: space-between;
  border-bottom: 1px solid #00003a !important;
}

.Map-download-site > span {
  display: flex;
  justify-content: space-between;
}

.Map-download-site img {
  height: 14px;
}

body.ar .Map-export-source {
  text-align: right;
}

.include-in-export {
  display: none;
}

body.ar .Map select {
  background-position: left 10px;
}

body.ar .Map-range span {
  text-align: right;
}

body.ar .Map-range span + span {
  text-align: left;
}

body.ar .Map-range div {
  background: -moz-linear-gradient(right, #363562 0%, #fc6d04 100%);
  background: -webkit-linear-gradient(right, #363562 0%,#fc6d04 100%);
  background: linear-gradient(to left, #363562 0%,#fc6d04 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#363562', startColorstr='#fc6d04',GradientType=1 );
}
